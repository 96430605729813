<template>
  <div class="page__wrapper">
    <ag-grid
      ref="summaryReportGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleDataSearch"
          >
            {{ $t('summary_report.dataSummary') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/system/user/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.download')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-download" @click="handleExportClick"/>
      </el-tooltip>
    </div>`
})

export default {
  name: 'SummaryReport',
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('thirdParty_reportExport.month'),
          component: { clearable: false, type: 'month', valueFormat: 'yyyyMM' }
        },
        {
          prop: 'area',
          type: 'Select',
          col: { xs: 4, sm: 4, md: 4 },
          component: { clearable: true, optionList: this.$getDictList('sys_office_region') },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        { slotName: 'tableOperation', col: { xs: 12, sm: 12, md: 12 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          width: 40,
          pinned: 'left',
          suppressSizeToFit: true,
          checkboxSelection: true
        },
        {
          headerName: this.$t('summary_report.name'),
          field: 'version',
          minWidth: 200
        },
        {
          headerName: this.$t('summary_report.date'),
          field: 'period',
          minWidth: 200
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 68,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {}
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
